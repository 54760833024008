<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ $t('table_title.Login_History') }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="tableColumns"
                            :items="loginHistory"
                            class="elevation-1"
                            hide-default-footer
                            :loading="isLoading"
                            :loading-text="$t('cms.loading')"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <!-- <v-dialog v-model="dialogCU" persistent max-width="500px"> </v-dialog> -->
                                    <v-spacer></v-spacer>

                                    <div class="search">
                                        <a-select :default-value="selectedField" @change="handleChangeSelect">
                                            <a-select-option
                                                v-for="(item, index) in fieldsSearch"
                                                :key="index"
                                                :value="item.value"
                                            >
                                                {{ item.title }}
                                            </a-select-option>
                                        </a-select>
                                        <a-input-search
                                            v-model="search"
                                            :placeholder="$t('header.search')"
                                            @change="onSearchItem"
                                        />
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.created_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.created_at_unix_timestamp) }}</span>
                                </div>
                            </template>

                            <template v-slot:[`item.email`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{
                                        (item.user && item.user.email) || (item.employee && item.employee.email) || ''
                                    }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.full_name`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{
                                        (item.user && item.user.full_name) ||
                                        (item.employee && item.employee.full_name) ||
                                        ''
                                    }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.result`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ $t(`cms.${item.result}`) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.ip_address`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.ip_address }}</span>
                                </div>
                            </template>
                        </v-data-table>
                        <div class="text-center py-5">
                            <f-pagination
                                v-model="page"
                                :pageSize="LoginHistoryPagination.pageSize"
                                :totalItems="LoginHistoryPagination.totalItems"
                                :disabled="isLoading"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24"></a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UserModal from '../../components/CMS/Modal/User.vue';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalNoti from '../../components/CMS//Modal/Noti.vue';
import { mixinFormats, mixinToasts } from '../../mixins';
import * as _ from 'lodash';

export default {
    mixins: [mixinFormats, mixinToasts],
    components: { UserModal, ModalDelete, ModalNoti },

    data() {
        return {
            tableTitle: this.$t('cms.LoginHistory'),
            tableColumns: [
                { text: this.$t('form.email'), value: 'email', sortable: false, width: '200px' },
                { text: this.$t('form.full_name'), value: 'full_name', sortable: false, width: '200px' },
                { text: this.$t('form.result'), value: 'result', sortable: false, width: '200px' },
                { text: 'IP', value: 'ip_address', sortable: false, width: '200px' },
                {
                    text: this.$t('form.created_at'),
                    value: 'created_at_unix_timestamp',
                    sortable: false,
                    width: '175px',
                },
            ],
            fieldsSearch: [
                {
                    title: this.$t('form.full_name'),
                    value: 'full_name',
                },
                {
                    title: this.$t('form.email'),
                    value: 'email',
                },
            ],
            selectedField: 'full_name',
            search: '',
            page: 1,
            cuIndex: -1,
            cuItem: {},
            noImage: require('../../assets/images/no_image.jpg'),
            formMessage: '',
        };
    },
    created() {
        this.hiddenFieldModal(this.dialogEdit);
    },
    computed: {
        ...mapState('loginHistory', ['isLoading', 'loginHistory', 'LoginHistoryPagination']),
        formTitle() {
            return this.cuIndex === -1 ? this.$t('cms.new_item') : this.$t('cms.edit_item');
        },
    },
    async mounted() {
        await this.getList({ page: this.page, field: this.selectedField, search: this.search });
    },
    watch: {
        page(val) {
            this.getList({ page: val, field: this.selectedField, search: this.search });
        },
    },
    methods: {
        ...mapActions('loginHistory', ['getLoginHistories']),
        async getList(data) {
            this.page = data?.page || 1;
            this.selectedField = data?.field;
            this.search = data?.search || '';
            await this.getLoginHistories(data);
        },
        initialize() {
            this.getList({ page: 1, field: 'title', search: '' });
        },
        hiddenFieldModal(val) {
            if (val === true) {
                this.propsField = this.tableColumns.filter(
                    (s) => s.value !== 'password' && s.value !== 'created_at' && s.value !== 'updated_at',
                );
            } else {
                this.propsField = this.tableColumns.filter((s) => s.value !== 'created_at' && s.value !== 'updated_at');
            }
        },

        onSearchItem: _.debounce(function (e) {
            this.getList({ page: 1, field: this.selectedField, search: this.search });
        }, 500),
        handleChangeSelect(value) {
            this.getList({ page: 1, field: value, search: this.search });
        },
    },
};
</script>

<style lang="scss">
.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}
</style>
