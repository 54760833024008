var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ padding: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h5',{staticClass:"font-semibold m-0"},[_vm._v(_vm._s(_vm.$t('table_title.Login_History')))])])],1)]},proxy:true}])},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableColumns,"items":_vm.loginHistory,"hide-default-footer":"","loading":_vm.isLoading,"loading-text":_vm.$t('cms.loading'),"page":_vm.page,"no-data-text":_vm.$t('cms.no_data_available')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('div',{staticClass:"search"},[_c('a-select',{attrs:{"default-value":_vm.selectedField},on:{"change":_vm.handleChangeSelect}},_vm._l((_vm.fieldsSearch),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1),_c('a-input-search',{attrs:{"placeholder":_vm.$t('header.search')},on:{"change":_vm.onSearchItem},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.created_at_unix_timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.getFormatDate(+item.created_at_unix_timestamp)))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-2"},[_c('span',[_vm._v(_vm._s((item.user && item.user.email) || (item.employee && item.employee.email) || ''))])])]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-2"},[_c('span',[_vm._v(_vm._s((item.user && item.user.full_name) || (item.employee && item.employee.full_name) || ''))])])]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-2"},[_c('span',[_vm._v(_vm._s(_vm.$t(("cms." + (item.result)))))])])]}},{key:"item.ip_address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-2"},[_c('span',[_vm._v(_vm._s(item.ip_address))])])]}}],null,true)}),_c('div',{staticClass:"text-center py-5"},[_c('f-pagination',{attrs:{"pageSize":_vm.LoginHistoryPagination.pageSize,"totalItems":_vm.LoginHistoryPagination.totalItems,"disabled":_vm.isLoading},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2)],1)],1),_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }